import './App.css';
import Ahp from './Ahp';


function App() {
  return (
    
      <Ahp></Ahp>
    
  );
}

export default App;
