import React, { useState, useEffect } from 'react';

export default function Ahp() {
  const [numParameters, setNumParameters] = useState(0);
  const [parameterNames, setParameterNames] = useState([]);
  const [preferredOverMatrix, setPreferredOverMatrix] = useState([]);
  const [columnSums, setColumnSums] = useState([]);
  const [normalizedMatrix, setNormalizedMatrix] = useState([]);
  const [vpValues, setVpValues] = useState([]);
  const [vpSum, setVpSum] = useState(0);
  const [normalizedColumnSums, setNormalizedColumnSums] = useState([]);
  const [cpValues, setCpValues] = useState([]);
  const [cpSum, setCpSum] = useState(0);
  const [overallPriorityD, setOverallPriorityD] = useState([]);
  const [rationalPriorityE, setRationalPriorityE] = useState([]);
  const [tmax, setTmax] = useState(0); // State variable to hold the value of Tmax
  const [eValuesReady, setEValuesReady] = useState(false); // State variable to track whether the E values are ready
  const [lambdaMax, setLambdaMax] = useState(''); // State variable to hold the value of λmax
  const [consistencyIndex, setConsistencyIndex] = useState(''); // State variable to hold the value of CI
  const [consistencyRatio, setConsistencyRatio] = useState(''); // State variable to hold the value of CR

  const handleParameterChange = (e, index) => {
    const newParameterNames = [...parameterNames];
    newParameterNames[index] = e.target.value;
    setParameterNames(newParameterNames);
  };

  const handlePreferredOverMatrixChange = (e, rowIndex, colIndex) => {
    const value = e.target.value;
    let newValue, newReciprocalValue;

    if (value.includes('/')) {
      // If the input value is a fraction
      const [numerator, denominator] = value.split('/');
      newValue = `${numerator}/${denominator}`;
      newReciprocalValue = parseFloat(denominator) / parseFloat(numerator);
    } else {
      // If the input value is a regular number
      newValue = isNaN(value) ? '' : parseFloat(value);
      newReciprocalValue = newValue !== 0 ? 1 / newValue : '';
    }

    const newPreferredOverMatrix = preferredOverMatrix.map((row, i) =>
      row.map((cell, j) => {
        if (i === rowIndex && j === colIndex) {
          return newValue;
        } else if (i === colIndex && j === rowIndex) {
          // Synchronize reciprocal value
          return newReciprocalValue.toString();
        } else {
          return cell;
        }
      })
    );
    setPreferredOverMatrix(newPreferredOverMatrix);
  };

  useEffect(() => {
    if (numParameters > 0) {
      const initialPreferredOverMatrix = Array.from({ length: numParameters }, (_, i) =>
        Array.from({ length: numParameters }, (_, j) => (i === j ? 1 : ''))
      );
      setPreferredOverMatrix(initialPreferredOverMatrix);
    }
  }, [numParameters]);

  useEffect(() => {
    if (preferredOverMatrix.length > 0) {
      const calculateColumnSums = () => {
        const sums = Array.from({ length: numParameters }, () => 0);
        for (let i = 0; i < numParameters; i++) {
          for (let j = 0; j < numParameters; j++) {
            sums[j] += parseFloat(preferredOverMatrix[i][j] || 0);
          }
        }
        setColumnSums(sums);
      };
      calculateColumnSums();
    }
  }, [preferredOverMatrix, numParameters]);

  useEffect(() => {
    if (
      preferredOverMatrix.length > 0 &&
      preferredOverMatrix.every(row => row.every(cell => cell !== '')) &&
      columnSums.every(sum => sum > 0)
    ) {
      const calculateVpValues = () => {
        const vpArray = preferredOverMatrix.map(row => {
          const rowProduct = row.reduce((acc, val) => acc * parseFloat(val), 1);
          return (rowProduct ** (1 / numParameters)).toFixed(2);
        });
        setVpValues(vpArray);
        const vpSumValue = vpArray.reduce((acc, vp) => acc + parseFloat(vp || 0), 0);
        setVpSum(vpSumValue);
      };
      calculateVpValues();
    }
  }, [preferredOverMatrix, numParameters, columnSums]);

  useEffect(() => {
    if (preferredOverMatrix.length > 0 && columnSums.every(sum => sum > 0)) {
      const calculateNormalizedMatrix = () => {
        const normalized = preferredOverMatrix.map((row, i) =>
          row.map((value, j) => (columnSums[j] !== 0 ? (value / columnSums[j]).toFixed(2) : ''))
        );
        setNormalizedMatrix(normalized);
      };
      calculateNormalizedMatrix();
    }
  }, [preferredOverMatrix, columnSums]);

  useEffect(() => {
    if (vpValues.length > 0 && vpSum > 0) {
      const calculateCpValues = () => {
        const cpArray = vpValues.map(vp => (parseFloat(vp || 0) / vpSum).toFixed(2));
        setCpValues(cpArray);
        const cpSumValue = cpArray.reduce((acc, cp) => acc + parseFloat(cp || 0), 0);
        setCpSum(cpSumValue);
      };
      calculateCpValues();
    }
  }, [vpValues, vpSum]);

  useEffect(() => {
    // Calculate overall priority D
    const calculateOverallPriorityD = () => {
      if (preferredOverMatrix.length > 0 && normalizedMatrix.length > 0) {
        const overallPriorityDArray = preferredOverMatrix.map((row, i) => {
          let overallPriorityD = 0;
          for (let j = 0; j < numParameters; j++) {
            overallPriorityD += parseFloat(row[j] || 0) * parseFloat(normalizedMatrix[j][0] || 0);
          }
          return overallPriorityD.toFixed(2);
        });
        setOverallPriorityD(overallPriorityDArray);
      }
    };
    calculateOverallPriorityD();
  }, [preferredOverMatrix, normalizedMatrix, numParameters]);

  useEffect(() => {
    // Calculate rational priority E
    const calculateRationalPriorityE = () => {
      if (overallPriorityD.length > 0 && cpValues.length > 0) {
        const rationalPriorityEArray = overallPriorityD.map((value, i) => {
          return (parseFloat(value) / parseFloat(cpValues[i] || 0)).toFixed(2);
        });
        setRationalPriorityE(rationalPriorityEArray);
        // Calculate Tmax after rationalPriorityE is set
        const sumOfE = rationalPriorityEArray.reduce((acc, value) => acc + parseFloat(value || 0), 0);
        setTmax(sumOfE / numParameters);
        // Set E values ready flag to true
        setEValuesReady(true);
      }
    };

    // Call the function to calculate rational priority E
    calculateRationalPriorityE();
  }, [overallPriorityD, cpValues, numParameters]);

  useEffect(() => {
    if (normalizedMatrix.length > 0) {
      // Calculate eigen value (λmax)
      const sumOfE = rationalPriorityE.reduce((acc, value) => acc + parseFloat(value || 0), 0);
      const lambdaMaxValue = sumOfE / numParameters;
      setLambdaMax(lambdaMaxValue.toFixed(2));

      // Calculate consistency index (CI)
      const consistencyIndexValue = (lambdaMaxValue - numParameters) / (numParameters - 1);
      setConsistencyIndex(consistencyIndexValue.toFixed(2));

      // Calculate consistency ratio (CR)
      const riValues = [0, 0, 0.58, 0.9, 1.12, 1.24, 1.32, 1.41, 1.45, 1.49];
      const ri = riValues[numParameters - 1];
      const consistencyRatioValue = consistencyIndexValue / ri;
      setConsistencyRatio(consistencyRatioValue.toFixed(2));
    }
  }, [normalizedMatrix, rationalPriorityE, numParameters]);

  const renderParameters = () => {
    const parameters = [];
    for (let i = 0; i < numParameters; i++) {
      parameters.push(
        <input
          key={i}
          type="text"
          placeholder={`Enter Parameter name ${i + 1}`}
          onChange={(e) => handleParameterChange(e, i)}
        />
      );
    }
    return parameters;
  };

  const renderMatrix = (matrix, showVpColumn, isNormalizedMatrix) => {
    // Check if matrix is defined
    if (!matrix || matrix.length === 0) {
      return null;
    }
  
    // Render the matrix
    const table = [];
    const headerRow = [<th key="empty"></th>];
  
    // Add header row
    for (let i = 0; i < numParameters; i++) {
      headerRow.push(<th key={`col-${i}`}>{parameterNames[i]}</th>);
    }
    if (showVpColumn) {
      headerRow.push(<th key="vp">VP</th>);
      headerRow.push(<th key="cp">CP</th>);
    }
    if (isNormalizedMatrix) {
      headerRow.push(<th key="sum">Sum</th>); // Add a column for sum
      headerRow.push(<th key="priority">PV [C]</th>); // Add a column for priority vector
      headerRow.push(<th key="overall-priority">OP D</th>); // Add a column for overall priority D
      headerRow.push(<th key="rational-priority">E</th>);
    }
    table.push(<tr key="header">{headerRow}</tr>);
  
    // Add rows
    for (let i = 0; i < numParameters; i++) {
      const row = [<th key={`row-${i}`}>{parameterNames[i]}</th>];
      let rowSum = 0; // Initialize row sum
      for (let j = 0; j < numParameters; j++) {
        const cellValue = matrix[i] ? matrix[i][j] : '';
        row.push(
          <td key={`${i}-${j}`}>
            <input
              type="text"
              style={{ width: '70px' }}
              value={cellValue}
              onChange={(e) => handlePreferredOverMatrixChange(e, i, j)}
            />
          </td>
        );
        // Check if matrix[i] exists before accessing matrix[i][j]
        if (isNormalizedMatrix && matrix[i]) {
          const numericCellValue = parseFloat(cellValue || 0);
          rowSum += numericCellValue;
        }
      }
      if (showVpColumn) {
        row.push(
          <td key={`${i}-vp`}>
            <input
              type="text"
              style={{ width: '70px' }}
              value={vpValues[i] || ''}
              readOnly
            />
          </td>
        );
        row.push(
          <td key={`${i}-cp`}>
            <input
              type="text"
              style={{ width: '70px' }}
              value={cpValues[i] || ''}
              readOnly
            />
          </td>
        );
      }
      if (isNormalizedMatrix) {
        const priorityVectorC = rowSum / numParameters; // Calculate priority vector C
        row.push(
          <td key={`${i}-sum`}>
            <input
              type="text"
              style={{ width: '70px' }}
              value={rowSum.toFixed(2)} // Display row sum
              readOnly
            />
          </td>
        );
        row.push(
          <td key={`${i}-priority`}>
            <input
              type="text"
              style={{ width: '70px' }}
              value={priorityVectorC.toFixed(3)} // Display priority vector C
              readOnly
            />
          </td>
        );
        row.push(
          <td key={`${i}-overall-priority`}>
            <input
              type="text"
              style={{ width: '70px' }}
              value={overallPriorityD[i] || ''} // Display overall priority D
              readOnly
            />
          </td>
        );
        row.push(
          <td key={`${i}-rational-priority`}>
            <input
              type="text"
              style={{ width: '70px' }}
              value={rationalPriorityE[i] !== undefined ? rationalPriorityE[i] : ''} // Display rational priority E
              readOnly
            />
          </td>
        );
      }
      table.push(<tr key={`row-${i}`}>{row}</tr>);
    }
  
    // Add column sum row
    if (!isNormalizedMatrix) {
      const columnSums = Array.from({ length: numParameters }, () => 0); // Initialize array to hold column sums
      for (let i = 0; i < numParameters; i++) {
        for (let j = 0; j < numParameters; j++) {
          columnSums[j] += parseFloat(matrix[i][j] || 0);
        }
      }
      const sumRow = [<th key="sum-label">Sum</th>];
      for (let j = 0; j < numParameters; j++) {
        sumRow.push(
          <td key={`sum-${j}`}>
            <input
              type="text"
              style={{ width: '70px' }}
              value={columnSums[j].toFixed(2)} // Display column sum
              readOnly
            />
          </td>
        );
      }
      if (showVpColumn) {
        const vpSum = vpValues.reduce((acc, vp) => acc + parseFloat(vp || 0), 0);
        const cpSum = cpValues.reduce((acc, cp) => acc + parseFloat(cp || 0), 0);
        sumRow.push(
          <td key="vp-sum">
            <input
              type="text"
              style={{ width: '70px' }}
              value={vpSum.toFixed(2)} // Display VP sum
              readOnly
            />
          </td>
        );
        sumRow.push(
          <td key="cp-sum">
            <input
              type="text"
              style={{ width: '70px' }}
              value={cpSum.toFixed(2)} // Display CP sum
              readOnly
            />
          </td>
        );
      }
      table.push(<tr key="sum-row">{sumRow}</tr>);
    } else {
      const normalizedColumnSums = Array.from({ length: numParameters }, () => 0); // Initialize array to hold column sums
      const overallPriorityDSum = overallPriorityD.reduce((acc, value) => acc + parseFloat(value || 0), 0);
      const rationalPriorityESum = rationalPriorityE.reduce((acc, value) => acc + parseFloat(value || 0), 0);
      for (let i = 0; i < numParameters; i++) {
        for (let j = 0; j < numParameters; j++) {
          normalizedColumnSums[j] += parseFloat(matrix[i][j] || 0);
        }
      }
      const sumRow = [<th key="sum-label">Sum</th>];
      for (let j = 0; j < numParameters; j++) {
        sumRow.push(
          <td key={`sum-${j}`}>
            <input
              type="text"
              style={{ width: '70px' }}
              value={normalizedColumnSums[j].toFixed(2)} // Display column sum
              readOnly
            />
          </td>
        );
      }
      sumRow.push(
        <td key="pv-c-sum">
          <input
            type="text"
            style={{ width: '70px' }}
            value={(overallPriorityDSum / numParameters).toFixed(2)} // Display sum of PV [C]
            readOnly
          />
        </td>
      );
      sumRow.push(
        <td key="op-d-sum">
          <input
            type="text"
            style={{ width: '70px' }}
            value={overallPriorityDSum.toFixed(2)} // Display sum of OP D
            readOnly
          />
        </td>
      );
      sumRow.push(
        <td key="e-sum">
          <input
            type="text"
            style={{ width: '70px' }}
            value={rationalPriorityESum.toFixed(2)} // Display sum of E
            readOnly
          />
        </td>
      );
      table.push(<tr key="sum-row">{sumRow}</tr>);
    }
  
    return table;
};



  
  
  

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Automation Process for Ahp - Analytical hierarchical process</h1>
      <hr />
      <div className="container">
        <label htmlFor="parameters">Enter the numbers of parameters:</label>
        <input
          type="number"
          id="parameters"
          placeholder="numbers of parameters"
          name="parameters"
          onChange={(e) => setNumParameters(parseInt(e.target.value, 10))}
          size="5"
        />
      </div>
      <div>
        {numParameters > 0 && renderParameters()}
      </div>
      <div style={{ marginTop: '20px' }}>
        {numParameters > 0 && (
          <>
            <h2>Preferred Over Matrix</h2>
            <table>
              <tbody>{renderMatrix(preferredOverMatrix, true, false)}</tbody>
            </table>
            <p> Where VP is eigenvectors and Cp is weighting coefficients</p>
            <h2>Normalized Matrix</h2>
            <table>
              <tbody>{renderMatrix(normalizedMatrix, false, true)}</tbody>
            </table>
            <p> Where PV [C] is Priority Vector, OP D is Overall Priority and E is Rational Priority</p>
            <h2>Calculation of eigen value (λmax), consistency index (CI) and consistency Ration (CR ) </h2>
            <lable>eigen value (λmax)</lable>
            <input type="text" value={lambdaMax} readOnly />
            <lable>Consistency index (CI)</lable>
            <input type="text" value={consistencyIndex} readOnly />
            <lable>Consistency Ration (CR)</lable>
            <input
              type="text"
              value={consistencyRatio}
              readOnly
              style={{ backgroundColor: parseFloat(consistencyRatio) <= 0.1 ? 'green' : 'red' }}
            />
          </>
        )}
      </div>
    </div>
  );
}
